<template>
  <div>
    <v-dialog v-model="$store.state.dialog" width="800">
      <v-card v-if="$global.state.loading">
        <Loading></Loading>
      </v-card>
      <v-card v-else outlined>
        <v-card-title>
          مشاركة الكتب
          <v-spacer></v-spacer>
          <v-btn color="error" icon @click="$store.state.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-container fluid>
          <v-tabs v-model="tab" color="primary" slider-color="primary">
            <v-tab>
              <v-icon>mdi-message-reply-text</v-icon>مشاركة مع شخص
            </v-tab>
            <v-tab>
              <v-icon>mdi-printer</v-icon>مشاركة مع منظمة
            </v-tab>
          </v-tabs>
          <v-form @submit.prevent="submit" class="mt-5">
            <div v-if="tab == 0">
              <h3 class="mb-2">
                الشخص
                <span class="required">*</span>
              </h3>
              <v-autocomplete
                v-if="tab == 0"
                v-model="sharedUsers"
              :items="users"
              item-text="fullName"
              item-value="id"
              hide-details="auto"
              :loading="$global.state.loading"
              outlined
              multiple
              :search-input.sync="searchUser"
              :rules="[$global.state.required()]"
              filled
              small-chips
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggle">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          sharedUsers.length > 0 ? 'indigo darken-4' : ''
                        "
                      >{{ sharedUsers.length == $global.state.users.length ? 'mdi-close-box' : 'mdi-minus-box'}}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>تحديد الكل</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </div>

            <div v-if="tab == 1">
              <h3 class="mb-2">
                المنظمة
                <span class="required">*</span>
              </h3>
              <v-alert outlined>
                <v-treeview
                  v-model="sharedOrganizations"
                  :items="organizations"
                  selectable
                  multiple
                  item-children="organizations"
                  selected-color="primary"
                ></v-treeview>
              </v-alert>
            </div>
            <div class="d-flex justify-end mt-5">
              <v-btn x-large type="submit" color="primary">
                <v-icon>mdi-send</v-icon>
                <span>إرسال</span>
              </v-btn>
            </div>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      document: {},
      tab: 0,
      users: [],
      organizations: [],
      searchUser: "",
      sharedUsers: [],
      sharedOrganizations: [],
      item: [],
      edited: []
    };
  },
  computed: {
    selectedDocuments() {
      return this.$store.state.itemDetails;
    }
  },
  methods: {
    toggle() {
      if (this.sharedUsers.length == this.$global.state.users.length) {
        this.sharedUsers = [];
      } else {
        this.sharedUsers = this.$global.state.users.map(user => user.id);
      }
    },
   
    async submit() {
      this.$global.state.loading = true;
      try {
        this.item = [];
        if (this.tab == 0) {
          this.sharedUsers.forEach(user => {
            this.selectedDocuments.forEach(id => {
              this.item.push({
                userId: user,
                organizationId: null,
                documentId: id
              });
            });
          });
        } else {
          this.sharedOrganizations.forEach(organization => {
            this.selectedDocuments.forEach(id => {
              this.item.push({
                userId: null,
                organizationId: organization,
                documentId: id
              });
            });
          });
        }
        console.log(this.item);
        await this.$http.post("/documentshare", this.item);
        this.$global.state.loading = false;
        this.$store.state.dialog = false;
        this.sharedOrganizations= [];
        this.sharedUsers = [];
        this.tab = 0;

      } catch (err) {
        console.log(err);
        this.$service.faild(err.response.data.message);
      } finally {
        this.$global.state.loading = false;
      }
    },
    relateOrg(org) {
      if (org.parentId == null && org.organizations.length > 0) {
        this.organizations.push(org);
      }
    },
    async getOrganizations() {
      await this.$global.dispatch("getOrganization");
      this.$global.state.organizations.forEach(org => {
        org.organizations = [];
        org.organizations = this.$global.state.organizations.filter(
          item => item.parentId == org.id
        );
        this.edited.push(org);
        this.relateOrg(org);
      });
      console.log("edited", this.edited);
    }
  },
  mounted() {
    this.getOrganizations();
    this.$global.dispatch("getUser");
    this.users = this.$global.state.users;
  },
  watch: {
    async searchUser(val) {
      if (val != "" && val != null) {
          let res = await this.$http.get("/user", {
            params: {
              fullName: val
            }
          });
          this.users = res.data.result;
      }
    }
  }
};
</script>
