<template>
<div>
    <v-card flat class="transparent">
        <v-card-title>
            <h2>البريد {{ type.title }}</h2>
            <v-spacer></v-spacer>
            <v-btn :to="`/documents/create/${$route.params.type}`" color="primary" large v-if="
            $store.state.user.role != 'Employee' ||
            $route.params.type == 'internal'
          ">
                <v-icon left>mdi-plus</v-icon>
                انشاء كتاب {{ type.single }}
            </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-container fluid>
            <v-form ref="filter">
                <v-row>
                    <v-col cols="3">
                        <v-text-field v-debounce:500ms="getItems" class="mr-1" label="بحث بواسطة عدد الصادر" hide-details prepend-inner-icon="search" outlined filled v-model="filter.Number" />
                    </v-col>
                    <v-col cols="3" v-if="type.type == 2">
                        <v-text-field v-debounce:500ms="getItems" class="mr-1" label="بحث بواسطة عدد الوارد" hide-details prepend-inner-icon="search" outlined filled v-model="filter.Sec_number" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field v-debounce:500ms="getItems" class="mr-1" label="بحث بواسطة الموضوع" hide-details prepend-inner-icon="search" outlined filled v-model="filter.Title" />
                    </v-col>
                    <v-col cols="3">
                        <v-select :items="priorities" @change="getItems" class="mr-1" item-text="text" item-value="id" label="بحث بواسطة الاهمية " hide-details prepend-inner-icon="search" outlined filled v-model="filter.Priority" />
                    </v-col>

                    <v-col cols="3">
                        <!-- <v-text-field
                v-debounce:500ms="getItems"
                class="mr-1"
                label="بحث بواسطة الجهة "
                hide-details=""
                prepend-inner-icon="search"
                outlined=""
                filled=""
                v-model="filter.Destination"
              />-->
                        <v-autocomplete item-text="name" :items="$global.state.ministries" item-value="id" required :search-input.sync="searchMinistry" filled outlined hide-details="auto" label="بحث بواسطة الجهة " v-model="filter.Category" @change="getItems" clearable />
                    </v-col>
                    <v-col cols="3">
                        <!-- {{ $route.path }} -->
                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="filter.Date" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="filter.Date" label="بحث حسب تاريخ الصادر" prepend-inner-icon="mdi-calendar" readonly outlined filled @change="getItems" v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker color="primary" :locale="$i18n.locale" v-model="filter.Date" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu = false">{{ $t("cancel") }}</v-btn>
                                <v-btn text color="primary" @click="$refs.menu.save(filter.Date)">{{ $t("save") }}</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="3" v-if="$route.path == '/documentsList/incoming'">
                        <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :return-value.sync="filter.Sec_date" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="filter.Sec_date" label="بحث حسب تاريخ الوارد" prepend-inner-icon="mdi-calendar" readonly outlined filled @change="getItems" v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker color="primary" :locale="$i18n.locale" v-model="filter.Sec_date" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu2 = false">{{ $t("cancel") }}</v-btn>
                                <v-btn text color="primary" @click="$refs.menu2.save(filter.Sec_date)">{{ $t("save") }}</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="3">
                        <v-autocomplete item-text="name" :items="$global.state.documentCategory" item-value="id" required :search-input.sync="searchCategory" filled outlined hide-details="auto" label="بحث بواسطة النوع " v-model="filter.Category" @change="getItems" clearable />
                    </v-col>
                    <v-col cols="3">
                        <v-combobox label="العناوين الفرعية" filled outlined v-model="tags" @change="
                  () => {
                    filter.Tags = tags.join(',');
                    getItems();
                  }
                " clearable hide-details="auto" multiple no-filter></v-combobox>
                    </v-col>
                    <v-col cols="12">
                        <div class="d-flex justify-end">
                            <v-btn @click="
                    () => {
                      $refs.filter.reset();
                      getItems();
                    }
                  " color="error">
                                <v-icon>mdi-rotate-left</v-icon>مسح البحث
                            </v-btn>
                            <v-btn class="mr-2" @click="shareDocuments()" color="primary">
                                <v-icon>mdi-share-all</v-icon>مشاركة الكتب المحددة
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-form>
            <v-tabs v-if="type.type == 4" v-model="shareTab" color="primary" class="pa-3">
                <v-tab>
                    <v-icon color="mx-2">mdi-view-dashboard</v-icon>للشخص
                </v-tab>
                <v-tab>
                    <v-icon color="mx-2">mdi-view-dashboard</v-icon>للمنظمة
                </v-tab>
            </v-tabs>
            <!-- autocomplete -->
            <v-data-table class="mytable mt-5" :items-per-page="$global.state.filter.pageSize" :items="$global.state.documents" :loading="$global.state.loading" :headers="headers()" hide-default-footer :loading-text="$t('loading')" :no-data-text="$t('no-data')" :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'">
                <template v-slot:item.select="{ item }">
                    <v-checkbox @click="selectDocument(item)" v-model="item.isSelected" />
                </template>
                <template v-slot:item.sec_date="{ item }">{{ item.sec_date.substr(0,10) }}</template>
                <template v-slot:item.date="{ item }">{{ item.date.substr(0,10) }}</template>
                <template v-slot:item.priority="{ item }">
                    <v-chip color="orange" v-if="item.priority == 0">انتباه</v-chip>
                    <v-chip color="error" v-if="item.priority == 1">عاجل</v-chip>
                    <v-chip color="grey" v-if="item.priority == 2">عادي</v-chip>
                    <v-chip color="indigo" v-if="item.priority == 3">سري</v-chip>
                </template>
                <template v-slot:item.created="{ item }">{{ item.created.substr(0,10) }}</template>
                <template v-slot:item.index="{ index }">{{ index + 1 }}</template>
                <template v-slot:item.actions="{ item , index}">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn :to="`/document/${item.id}`" v-on="on" icon>
                                <v-icon color="primary">mdi-eye</v-icon>
                            </v-btn>
                        </template>
                        <span>عرض التفاصيل</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click="deleteItem(item.id, index)" v-on="on" icon>
                                <v-icon color="error">delete</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t("hthf") }}</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-container>
    </v-card>
    <shareDialog />
    <Pagination />

</div>
</template>

<script>
import shareDialog from './shareDialog.vue'
export default {
    components: {
        shareDialog
    },
    computed: {
        type() {
            let obj;
            switch (this.$route.params.type) {
                case "outgoing":
                    obj = {
                        type: 1,
                        title: "الصادر",
                        single: "صادر"
                    };
                    break;
                case "incoming":
                    obj = {
                        type: 2,
                        title: "الوارد",
                        single: "وارد"
                    };
                    break;
                case "internal":
                    obj = {
                        type: 3,
                        title: "مذكرات داخلية",
                        single: "مذكرة"
                    };
                    break;
                default:
                    obj = {
                        type: 4,
                        title: "المشاركة",
                        single: "المشاركة"
                    };
                    break;
            }
            return obj;
        }
    },
    data() {
        return {
            shareTab: 0,
            tags: [],
            menu: false,
            menu2: false,
            searchCategory: null,
            searchMinistry: null,
            filter: {
                Number: null,
                Sec_number: null,
                Title: null,
                Type: 0,
                Priority: null,
                Destination: null,
                Category: null,
                sort: "desc",
                Source: null,
                Sec_date: null,
                Tags: null
            },
            priorities: [{
                    text: "انتباه",
                    id: 0
                },
                {
                    text: "عاجل",
                    id: 1
                },
                {
                    text: "عادي",
                    id: 2
                },
                {
                    text: "سري",
                    id: 3
                }
            ],
            selectedDocuments: [],
            items: []
        };
    },
    methods: {
        shareDocuments() {
            if (this.selectedDocuments.length > 0) {
                this.$store.state.itemDetails = this.selectedDocuments;
                this.$store.state.dialog = true;
            } else {
                this.$service.faild("يرجى تحديد كتب اولاً");
            }
        },
        selectDocument(item) {
            if (item.isSelected) {
                this.selectedDocuments.push(item.id);
            } else {
                this.selectedDocuments = this.selectedDocuments.filter(
                    i => i != item.id
                );
            }
            console.log(this.selectedDocuments);
        },
        async getItems() {
            this.$global.dispatch("getDocument", this.filter);
        },
        deleteItem(id, i) {
            this.$service.deleteItem(id, `Document`, `delete`);

            this.$eventBus.$once(`delete`, () => {
                setTimeout(() => {
                    this.getItems();
                }, 1000);
            });
        },
        headers() {
            return [{
                    text: "",
                    value: "select"
                },
                {
                    text: this.type.type != 2 ? "رقم الكتاب" : "رقم الوارد",
                    value: this.type.type != 2 ? "number" : "sec_number"
                },
                {
                    text: this.type.type != 2 ? "تاريخ الكتاب" : "تاريخ الوارد",
                    value: this.type.type != 2 ? "date" : "sec_date"
                },

                {
                    text: "الجهة",
                    value: "ministry.name"
                },

                {
                    text: "موضوع الكتاب",
                    value: "title"
                },
                // {
                //   text: "رقم الوارد",
                //   value: "sec_number",
                // },
                // {
                //   text: "تاريخ الوارد",
                //   value: "sec_date",
                // },
                {
                    text: "الاهمية",
                    value: "priority"
                },
                {
                    text: "النوع",
                    value: "category"
                },
                {
                    text: "الاجراءات",
                    value: "actions"
                }
            ];
        }
    },
    created() {
        switch (this.$route.params.type) {
            case "outgoing":
                this.filter.Type = 1;
                break;
            case "incoming":
                this.filter.Type = 2;
                break;
            case "internal":
                this.filter.Type = 3;
                break;
            default:
                this.filter.Type = null;
                this.filter.ShareUserId = this.$store.state.user.id;
                this.shareTab = 0;
                break;
        }
        this.$global.dispatch("getDocumentCategory", {});
        this.$global.dispatch("getMinistry", {});
        this.getItems();
        this.$eventBus.$on(`refresh`, () => {
          this.getItems();
        });
    },
    watch: {
        filter: {
            handler() {
                console.log(this.type);
                this.getItems();
                this.$forceUpdate();
                this.headers();
            },
            deep: true
        },

        shareTab() {
            if (this.shareTab == 0) {
                this.filter.ShareOrganiztionId = null;
                this.filter.ShareUserId = this.$store.state.user.id;
            } else {
                this.filter.ShareUserId = null;
                this.filter.ShareOrganiztionId = this.$store.state.user.user.organization.id;
            }
            this.getItems();
        },
        searchMinistry() {
            this.$global.dispatch("getMinistry", {
                search: this.searchMinistry
            });
        },
        searchCategory() {
            this.$global.dispatch("getDocumentCategory", {
                search: this.searchCategory
            });
        }
    }
};
</script>
